import { FC, useContext, useEffect, useMemo, useState } from 'react';
import styles from './AssociationDetailsComponent.module.scss';
import { SolutionContext, constructBaseSolutionRequest } from '../../features/SolutionDetails/SolutionDetails';
import { ComponentAssociation, ConfigurableControl, FeatureAssociation, FeatureAssociationRequest, FeatureDefinition, PackagedSolutionProductRelationType, ProductInfo, SolutionDefinition, SolutionDefnitionRequest, SolutionType, SpecificationParameterAssociation, SpecificationParameterAssociationRequest, SpecificationParameterDefinition, Status } from '../../data/model/DataModels';
import FeatureCardIncremental from '../FeatureCardIncremental/FeatureCardIncremental';
import { useUpdateSolutionMutation } from '../../data/api/CatalogueApi';
import { ToasterContext } from '../../features/AppLayoutView/AppLayoutView';
import { confirmDialog } from 'primereact/confirmdialog';
import { cloneDeep } from 'lodash';
import DisplaySpecificationParameterCards from '../../features/ProductView/ProductFeatures/DisplaySpecificationParameterCards/DisplaySpecificationParameterCards';
import SpinnerComponent from '../Spinner/SpinnerComponent';
import { Tooltip } from 'primereact/tooltip';
import { confirmPopup } from 'primereact/confirmpopup';
import { Button } from 'primereact/button';
import DisplayFeatureCards from '../../features/ProductView/ProductFeatures/DisplayFeatureCards/DisplayFeatureCards';
import { Dropdown } from 'primereact/dropdown';

interface AssociationDetailsComponentProps {
  refetchSolution: Function;
  isEditable: boolean;
  setFeatureInEditMode: Function;
  featureInEditMode: boolean;
  removeProduct?: Function;
  deleteComponent?: Function;
  addFeatures?: Function;
  previewUpgradeProduct?: Function;
  isNewFeatureVersionAvailable?: Function;
  setSelectedFeatureForUpgrade?: Function;
  updateProduct: Function;
}

const AssociationDetailsComponent: FC<AssociationDetailsComponentProps> = ({ refetchSolution, isEditable, setFeatureInEditMode, featureInEditMode, removeProduct, deleteComponent, addFeatures, previewUpgradeProduct, isNewFeatureVersionAvailable, setSelectedFeatureForUpgrade, updateProduct }) => {

  const parentSolutionState = useContext(SolutionContext);
  const [solution, setSolution] = useState(parentSolutionState.solutionData);
  const toaster = useContext(ToasterContext);
  const [showSpecificationParamModal, setShowSpecificationParamModal] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState({} as FeatureDefinition);
  const [updateSolution, { isLoading: isLoadingUpdateSolution }] = useUpdateSolutionMutation();
  const [showWarning, setShowWarning] = useState("");
  const [showAddFeaturesModalFor, setShowAddFeaturesModalFor] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<{ [associatedProductCode: string]: { relationType: string } }>({});

  useEffect(() => {
    setSolution(parentSolutionState.solutionData);
  }, [parentSolutionState.solutionData])

  useEffect(() => {
    parentSolutionState.solutionData?.products.forEach(product => {
      setSelectedOptions(prevState => ({
        ...prevState,
        [product.code]: {relationType : product.relationType}
      }));
    });
  }, [parentSolutionState.solutionData]);
  

  if (!parentSolutionState.solutionData || !solution) {
    return <></>
  }

  const selectedTypeTemplate = (option: any) => {
    return (
      <div className={styles.customDropdownItem}>
        <span className={styles.optionName} >{option.name}</span>
        <div className={styles.optionDescription}>{option.description}</div>
      </div>
    );
  };

  const handleDropdownChange = (relationType: string, associatedProductCode: any) => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [associatedProductCode]: {relationType : relationType},
    }));
    updateProduct(relationType, associatedProductCode);
  };

  const getRelationTypeNameFromCode = (relationTypeCode: string) => {
    return PackagedSolutionProductRelationType.filter(relationTypeObj => relationTypeObj.code === relationTypeCode).map(matchedObj => matchedObj.name);
  }

  const getProductCard = () => {
    return cloneDeep(solution.products).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLocaleLowerCase())).map((product, index) => {
      return (
        <div className={styles.productCard} data-testid={`productCard-${index}`} key={product.code}>
          <div className={styles.productNameSection}>
            <div className={styles.productNameAndUpgrade}>
              <div className={styles.productName}>{product.name}</div>
              {product.latestActiveVersion > product.version! ?
                <div>
                  <span className={styles.featureVersionAvailable}>New version available</span>
                  <span className={styles.productUpgrade} onClick={(e) => previewUpgradeProduct && previewUpgradeProduct(e, product)} data-testid={`previewOrUpgradeLink${index + 1}`}>
                    {isEditable && (solution.status === Status.DRAFT) ? "Upgrade to v" : "Preview v"}{product.latestActiveVersion}
                  </span>
                </div>
                : ""
              }
            </div>
            <div className={styles.selectProductType}>
              {isEditable ?
                <Dropdown
                  className={styles.relationType}
                  value={selectedOptions[product.code]?.relationType || product.relationType}
                  options={PackagedSolutionProductRelationType}
                  onChange={(e) => handleDropdownChange(e.value, product.code)}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Select Product Type"
                  itemTemplate={selectedTypeTemplate}
                  data-testid="ProductRelation"
                  scrollHeight="18rem"
                />
                :
                <span className={styles.relationTypeViewTag}>
                  <span className={`${styles.relationTypeViewText} ${product.relationType === 'CORE' ? `${styles.relationTypeViewTextCore}` : `${styles.relationTypeViewTextOptional}`}`}>
                    {getRelationTypeNameFromCode(product.relationType)}
                  </span>
                </span>
              }
              {isEditable &&
                <Button icon="pi pi-trash" className={`p-button-outlined  p-button-secondary ${styles.deleteProductIcon}`} onClick={(e) => removeProduct && removeProduct(e, product)} data-testid={`deleteProduct-${index + 1}`}></Button>
              }
            </div>
          </div>
          {getComponentCard(product.components, product.features, product.specificationParameters, product.code)}
        </div>
      );
    });
  }

  const processAndAddFeatures = (features: FeatureAssociation[]) => {
    setShowAddFeaturesModalFor("");
    addFeatures && addFeatures(features);
  }

  const getAssociatedFeatureCodes = () => {
    const assocFeatures = solution.features.filter(feature => feature.componentRef == showAddFeaturesModalFor);
    return assocFeatures.map(feat => feat.featureRef);
  };

  const getFeaturesForComponent = (componentAssociation: ComponentAssociation) => {
    return solution.features?.filter(({ componentRef }) => componentAssociation.componentRef === componentRef);
  }

  const checkAllFeatureAssociation = (componentAssociation: ComponentAssociation) => {
    const sourceComponent = solution.components.find(comp => comp.componentRef === componentAssociation.componentRef);
    return sourceComponent ? !(sourceComponent?.definition.numberOfFeatures > getFeaturesForComponent(componentAssociation).length) : true;
  }

  const showAddFeatureModal = (event: any, componentAssociation: ComponentAssociation) => {
    event.preventDefault();
    event.stopPropagation();
    setShowAddFeaturesModalFor(componentAssociation.componentRef);
  }

  const confirmComponentDelete = (event: any, componentRef: string) => {
    event.preventDefault();
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteComponent && deleteComponent(componentRef),
      reject: () => null
    });
  }

  const getComponentCard = (components: ComponentAssociation[], features: FeatureAssociation[], specificationParameters: SpecificationParameterAssociation[], productCode: String) => {
    return components.map((component, index) => {
      return (
        <div data-testid={`componentCard-${index}`}>
          {
            solution.productType === SolutionType.INTEGRATED_SOLUTION ?
              <div className={styles.componentCard}>
                <div className={styles.componentSection}>
                  <div className={styles.componentName}>{component.definition.name}</div>
                  <div className={styles.componentActionBtn}>
                    <Button onClick={(e) => showAddFeatureModal(e, component)} data-testid={`addfeature-${index}`} disabled={checkAllFeatureAssociation(component)}
                      className={`p-button-outlined p-button-secondary tooltipAddFeature-${index}`}
                      tooltip={checkAllFeatureAssociation(component) ? "All features are already associated \nwith the component" : ""} tooltipOptions={{ showOnDisabled: true, position: "top" }}>
                      <i className='pi pi-plus' />
                      <span>Add Feature</span>
                    </Button>
                    {/* <Tooltip target={`.tooltipAddFeature-${index}`} className="general-tooltip" autoHide={true} position="top" disabled={!checkAllFeatureAssociation(component)}>
                          All features are already associated with the component
                        </Tooltip> */}
                    <Button icon="pi pi-trash" className={`p-button-outlined p-button-secondary`} onClick={(e) => confirmComponentDelete(e, component.componentRef)} data-testid={`deleteComponent-${index}`}></Button>
                  </div>
                </div>
                <div className={styles.featureList}>
                  {features.filter(feature => feature.componentRef === component.componentRef).map(feature => showFeature(feature, specificationParameters.filter(sp => sp.featureRef === feature.featureRef && sp.featureVersion === feature.featureVersion), true))}
                </div>
              </div> :
              <>
                <div className={styles.componentName}>{component.definition.name}</div>
                <div className={styles.featureList}>
                  {features.filter(feature => feature.componentRef === component.componentRef).map(feature => showFeature(feature, specificationParameters.filter(sp => sp.featureRef === feature.featureRef && sp.featureVersion === feature.featureVersion), false))}
                </div>
              </>
          }
        </div>
      )
    });
  }

  const saveFeature = () => {
    if (!parentSolutionState.solutionData) {
      return;
    }
    const solutionBaseRequest = constructBaseSolutionRequest(parentSolutionState.solutionData);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      specificationParameters: solution.specificationParameters,
      features: solution.features
    };

    return updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        parentSolutionState.setSolutionData(
          {
            ...parentSolutionState.solutionData as SolutionDefinition,
            lockingVersion: response.lockingVersion,
            specificationParameters: response.specificationParameters,
            features: response.features
          }
        );
        const responseUpgradedFeatures = response.components.flatMap((component) => component.upgradedFeatureCode);
        const solutionUpgradedFeatures = parentSolutionState?.solutionData?.components.flatMap((component) => component.upgradedFeatureCode);

        if (solutionUpgradedFeatures && responseUpgradedFeatures.length !== solutionUpgradedFeatures.length) {
          refetchSolution();
        }

        toaster.showToast('success', 'Successfully updated feature');
      },
      () => {
        toaster.showToast('error', 'Failed to update feature');
      }
    );
  }

  const onClickOfAddSpecParam = (feature: FeatureDefinition) => {
    setShowSpecificationParamModal(true);
    setSelectedFeature(feature);
  }

  const deleteFeature = (featureCode: string) => {
    if (!parentSolutionState.solutionData) {
      return;
    }

    const featureAssociations: FeatureAssociationRequest[] = parentSolutionState.solutionData.features.filter(feature => feature.featureRef !== featureCode);
    const specificationParameterAssociations: SpecificationParameterAssociationRequest[] = parentSolutionState.solutionData.specificationParameters.filter(specificationParameter => specificationParameter.featureRef !== featureCode);

    const solutionBaseRequest = constructBaseSolutionRequest(parentSolutionState.solutionData);
    const solutionRequestData: SolutionDefnitionRequest = {
      ...solutionBaseRequest,
      features: featureAssociations,
      specificationParameters: specificationParameterAssociations
    };

    updateSolution(solutionRequestData).unwrap().then(
      (response) => {
        parentSolutionState.setSolutionData(
          {
            ...parentSolutionState.solutionData as SolutionDefinition,
            lockingVersion: response.lockingVersion,
            features: response.features,
            specificationParameters: response.specificationParameters
          }
        );
        setSolution({
          ...solution,
          specificationParameters: response.specificationParameters,
          features: response.features
        });
        toaster.showToast('success', 'Successfully deleted feature');
      },
      () => {
        toaster.showToast('error', 'Failed to delete feature');
      }
    );
  };

  const confirmRemoveFeature = (feature: FeatureAssociation) => {
    confirmDialog({
      message: `Are you sure you want to remove feature ${feature.definition.name} from this solution?`,
      header: 'Remove feature?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteFeature(feature.featureRef)
    });
  }

  const deleteSpecificationParameterInSolution = (specCode: string) => {
    const specificationParameterAssociations: SpecificationParameterAssociation[] = solution.specificationParameters.filter(specificationParameter => specificationParameter.specificationParamRef !== specCode);
    setSolution({
      ...solution,
      specificationParameters: specificationParameterAssociations
    });
  }

  const confirmRemoveSpecificationParameter = (specification: SpecificationParameterDefinition) => {
    confirmDialog({
      message: `Are you sure you want to remove specification parameter ${specification.name} from this solution?`,
      header: 'Remove specification parameter?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => deleteSpecificationParameterInSolution(specification.code)
    });
  }

  const saveFeatureOptionality = (featureParam: FeatureDefinition, isChangedFromCoreToOptional: boolean) => {
    let updatedFeature = solution.features.map((param) => {
      let updatedValue = featureParam.code === param.featureRef;
      if (updatedValue) {
        return {
          ...param,
          serviceDefault: featureParam.serviceDefault,
        };
      }
      return param;
    });
    let updatedSPs;
    if (isChangedFromCoreToOptional) {
      updatedSPs = solution.specificationParameters.map((sp) => {
        let updatedSP = sp.featureRef === featureParam.code;
        if (updatedSP) {
          let config: ConfigurableControl = cloneDeep(sp.configurableAtContracting!);
          config.enabled = false;
          config.allowMultiselect = false;
          config.choiceRequired = false;
          return {
            ...sp,
            serviceDefault: "not_included",
            configurableAtContracting: config
          } as SpecificationParameterAssociation;
        }
        return sp;
      });
    }
    else updatedSPs = solution.specificationParameters.map((sp) => {
      let updatedSp = sp.featureRef === featureParam.code
      if (updatedSp) {
        return {
          ...sp,
          serviceDefault: sp.serviceDefault
        } as SpecificationParameterAssociation
      }
      return sp;
    });
    setSolution({
      ...solution,
      features: updatedFeature,
      specificationParameters: updatedSPs
    });
  };

  const showFeature = (feature: FeatureAssociation, specificationParameters: SpecificationParameterAssociation[], editable: boolean) => {
    const associatedSpecParams = specificationParameters
      .map((specParam) => {
        return {
          ...specParam.definition,
          ...specParam,
          code: specParam.specificationParamRef,
          name: specParam.definition.name,
          featureServiceDefault: feature.serviceDefault
        };
      }) as SpecificationParameterDefinition[];
    return (
      <div className={styles.featureCard}>
        <FeatureCardIncremental key={feature.featureRef}
          feature={
            {
              ...feature.definition,
              service: feature.service,
              associatedProducts: [{ code: solution.code }] as ProductInfo[],
              serviceDefault: feature.serviceDefault,
              specificationParameters: specificationParameters
                .map((specParam) => { return { ...specParam.definition, featureServiceDefault: feature.serviceDefault } })
            }
          }
          saveFeature={saveFeature}
          specificationParameterAssociations={associatedSpecParams}
          addSpecificationParameter={() => onClickOfAddSpecParam(feature.definition!)}
          deleteFeature={() => confirmRemoveFeature(feature)}
          deleteSpecification={(specification: SpecificationParameterDefinition) => confirmRemoveSpecificationParameter(specification)}
          isConfigurable={editable ? isEditable : false}
          usedIn={"solution"}
          isNewVersionAvailable={isNewFeatureVersionAvailable}
          saveFeatureOptionality={saveFeatureOptionality}
          setFeatureInEditMode={setFeatureInEditMode}
          featureInEditMode={featureInEditMode}
          saveSpecificationParameters={saveSpecificationParameters}
          onFeatureEditCancel={onFeatureCancel}
          setSelectedFeatureForUpgrade={() => setSelectedFeatureForUpgrade && setSelectedFeatureForUpgrade(feature)}
        />
      </div>
    );
  };

  const onFeatureCancel = () => {
    setSolution({
      ...solution,
      features: parentSolutionState.solutionData?.features,
      specificationParameters: parentSolutionState.solutionData?.specificationParameters
    } as SolutionDefinition);
  }

  const saveSpecificationParameters = (specParamsOfAFeature: SpecificationParameterDefinition[]) => {
    const updatedSpecificationParameterAssociations: SpecificationParameterAssociation[] = solution.specificationParameters.map(param => {
      let updatedValue = specParamsOfAFeature.find(spec => spec.code === param.specificationParamRef);
      if (updatedValue) {
        return {
          ...param,
          configurableAtBooking: updatedValue.configurableAtBooking,
          configurableAtContracting: updatedValue.configurableAtContracting,
          configurableAtAssociation: updatedValue.configurableAtAssociation,
          serviceDefault: updatedValue.serviceDefault,
          configuration: { ...param.configuration, value: updatedValue.configuration.value }
        }
      }
      return param;
    });
    setSolution({
      ...solution,
      specificationParameters: updatedSpecificationParameterAssociations
    });
  }

  const getAssociatedSpecParamsOfFeature = (feature: FeatureDefinition) => {
    return solution.specificationParameters?.filter(specParam => specParam.featureRef === feature.code).map(specParam => specParam.specificationParamRef);
  }

  const addSpecificationParameter = (specParams: SpecificationParameterDefinition[]) => {
    const mapSpecParamDefinitionToAssociation = (): SpecificationParameterAssociation[] => {
      return specParams.map(specParam => {
        const selectedFeatureAssociation = solution.features.find(feature => feature.featureRef === specParam.featureRef);
        return {
          featureRef: specParam.featureRef,
          featureVersion: specParam.featureVersion,
          componentRef: specParam.componentRef,
          definition: specParam,
          specificationParamRef: specParam.code,
          configuration: specParam.configuration,
          configurableAtContracting: specParam.configurableAtContracting,
          configurableAtBooking: specParam.configurableAtBooking,
          note: "",
          serviceDefault: selectedFeatureAssociation && selectedFeatureAssociation.serviceDefault === 'not_included' ? 'not_included' : specParam.serviceDefault
        } as SpecificationParameterAssociation
      })
    }
    setSolution({
      ...solution,
      specificationParameters: [...solution.specificationParameters, ...mapSpecParamDefinitionToAssociation()]
    });
  }

  return (
    <div className={styles.associationDetailsComponent} data-testid="view-details-section">
      {isLoadingUpdateSolution && <SpinnerComponent />}
      {solution?.productType === SolutionType.PACKAGED_SOLUTION ? getProductCard() : getComponentCard(solution.components, solution.features, solution.specificationParameters, "")}
      {
        showSpecificationParamModal &&
        <DisplaySpecificationParameterCards header={selectedFeature.name} actionShowDialog={() => setShowSpecificationParamModal(false)} activeSpecificationParameters={getAssociatedSpecParamsOfFeature(selectedFeature)} componentRef={selectedFeature.componentRef} featureRef={selectedFeature.code} acceptAction={(specParams: SpecificationParameterDefinition[]) => addSpecificationParameter(specParams)} />
      }
      {
        showAddFeaturesModalFor !== "" &&
        <DisplayFeatureCards header={"Component name"} onHide={() => setShowAddFeaturesModalFor("")} activeFeatures={getAssociatedFeatureCodes()} componentRef={showAddFeaturesModalFor} acceptAction={(features: FeatureAssociation[]) => processAndAddFeatures(features)} />
      }
    </div>
  );
}

export default AssociationDetailsComponent;
