import { cloneDeep, isEqual } from "lodash";
import { Button } from "primereact/button";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import ScopeCard2 from "../../components/ScopeComponents/ScopeCard2/ScopeCard2";
import { uuidv4 } from "../../components/utils/utils";
import { Dimension, DimensionType, FamilyDimensionAssociation, ProductHeaders, Scope } from "../../data/model/DataModels";
import { sectionType } from "../../data/model/SharedDataInterfaces";
import styles from "./ApplicabilitiesView.module.scss";
import { useGetSelectedDefaultDimensionsQuery } from "../../data/api/CatalogueApi";
import { EnvConfig } from "../../EnvConfig";
import SpinnerComponent from "../../components/Spinner/SpinnerComponent";
import { ToasterContext } from "../AppLayoutView/AppLayoutView";
import { useGetDimensionTypesQuery, useGetTreeTypeDimensionDataQuery } from "../../data/api/RefDataApi";
import EditDimensionView from "../EditDimensionView/EditDimensionView";
import { buildDimensionsWithAllValues } from "../../components/ScopeComponents/ScopeHelper";

export interface ApplicabilitiesViewProps {
  familyCode: string,
  scopes: Scope[];
  editMode: boolean;
  saveStateFn: Function;
  isScopesValid: Function;
  isAuthorisedToEdit: boolean;
  headerInfo?: ProductHeaders;
}

const ApplicabilitiesView: FC<ApplicabilitiesViewProps> = ({ familyCode, scopes, editMode, saveStateFn, isScopesValid, isAuthorisedToEdit = false, headerInfo }) => {

  const [invalidScopes, setInvalidScopes] = useState([] as string[]);
  const { data: defaultDimensionsFamilyData = [] as FamilyDimensionAssociation[], error: errorLoadingDefaultDimensions, isLoading: isLoadingDefaultDimensions } = useGetSelectedDefaultDimensionsQuery(familyCode, { skip: familyCode === "" });
  const { data: allDimensionsData = [], error: errorAllDimensionsData, isLoading: isLoadingAllDimensionsData } = useGetDimensionTypesQuery();
  const { data: regions = [] } = useGetTreeTypeDimensionDataQuery("imports");


  const [dimensionForEdit, setDimensionForEdit] = useState({} as Dimension);
  const [dimensionSibling, setDimensionSibling] = useState(undefined as Dimension | undefined);
  const [scopeForEdit, setScopeForEdit] = useState({} as Scope);

  const toaster = useContext(ToasterContext);

  const getDimensionsForStaticScope = () => {
    const defaultDimensions = defaultDimensionsFamilyData.length ? defaultDimensionsFamilyData.map(dimension => dimension.dimensionCode) : allDimensionsData.map(dimension => dimension.code).filter(dimensionCode => !(dimensionCode == DimensionType.Locations || dimensionCode == DimensionType.LocationsCountry));
    return buildDimensionsWithAllValues(defaultDimensions, allDimensionsData, regions);
  }

  const staticScope: Scope = {
    index: 0,
    dimensions: getDimensionsForStaticScope(),
    code: "",
    codeUI: `applicability-default`,
    name: "Applicability Scope 01",
    key: "applicability-scope-01",
  };

  if (errorAllDimensionsData) {
    toaster.showToast('error', 'Failed to load dimensions.');
  }

  if (errorLoadingDefaultDimensions) {
    toaster.showToast('error', 'Failed to load default dimensions.');
  }

  const getDimensionDefinition = (code: string) => {
    const dimensionDefinition = allDimensionsData?.find(dimension => dimension.code == code);
    if (dimensionDefinition) {
      return dimensionDefinition;
    }
    return undefined;
  }

  useEffect(() => {
    if (invalidScopes.length === 0) {
      isScopesValid(true);
    } else {
      isScopesValid(false);
    }
  }, [invalidScopes]);

  const checkScopesValidation = (
    isValid: boolean,
    scopeCode: string
  ) => {
    if (!isValid) {
      if (!invalidScopes.includes(scopeCode)) {
        setInvalidScopes([
          ...invalidScopes,
          scopeCode,
        ]);
      }
    } else {
      if (invalidScopes.length > 0) {
        if (
          invalidScopes.find((field) => field == scopeCode)
        ) {
          setInvalidScopes(
            invalidScopes.filter(
              (field) => field !== scopeCode
            )
          );
        }
      }
    }
  };

  const addScope = () => {
    const calculateScopeDimensions = () => {
      const allPresentDimensions = [...new Set(scopes.map(scope => scope.dimensions).flat().map(dimension => dimension.type))];
      if (allPresentDimensions.includes("locations") && (allPresentDimensions.includes("imports") || allPresentDimensions.includes("exports"))) {
        return getDimensionsForStaticScope();
      }
      if (allPresentDimensions.includes("locations")) {
        return getDimensionsForStaticScope().filter(dimension => dimension.type != "imports" && dimension.type !== "exports")
      }
      return getDimensionsForStaticScope().filter(dimension => dimension.type != "locations")
    }
    const scope: Scope = {
      index: 0,
      dimensions: calculateScopeDimensions(),
      code: "",
      codeUI: `applicability-${uuidv4()}`,
      name: "",
      key: "",
    };
    saveScope(scope);
  };

  const updateScope = (newScope: Scope) => {
    if (scopes.length === 0) {
      saveStateFn(sectionType.APPLICABILITY, [newScope]);
    }
    else {
      const updatedScopes = scopes?.map(scope => {
        if (newScope.code && newScope.code !== "" && scope.code === newScope.code)
          return newScope;
        else if (newScope.codeUI && newScope.codeUI !== "" && scope.codeUI === newScope.codeUI)
          return newScope;
        else
          return scope;
      });
      saveStateFn(sectionType.APPLICABILITY, updatedScopes);
    }
  };

  const deleteScope = (code: string, codeUI: string) => {
    let filteredScopes = scopes.filter(scope => code && code !== "" ? scope.code !== code : scope.codeUI !== codeUI).map((scope, index) => {
      scope.index = index;
      return scope;
    });
    if (filteredScopes.length === 1 && isEqual(
      filteredScopes[0],
      staticScope)) {
      saveStateFn(sectionType.APPLICABILITY, []);
    }
    else {
      saveStateFn(sectionType.APPLICABILITY, filteredScopes);
    }
  };

  const saveScope = (newScope: Scope) => {
    if (scopes.length === 0)
      saveStateFn(sectionType.APPLICABILITY, [
        staticScope,
        newScope,
      ]);
    else {
      saveStateFn(sectionType.APPLICABILITY, [
        ...scopes,
        newScope,
      ]);
    }
  };

  const getScope = (scopes: Scope[]) => {
    return scopes.length > 0 ? scopes : [staticScope];
  }

  const onEditDone = (scope: Scope, values: any = [], countryValues: any[]) => {
    let scopeCopy = cloneDeep(scope);
    const dimensionIndex = scopeCopy.dimensions.findIndex(dimension => dimension.type === dimensionForEdit.type);
    scopeCopy.dimensions[dimensionIndex].value = values;
    if(dimensionForEdit.type === DimensionType.Export) {
      const dimensionSiblingIndex = scopeCopy.dimensions.findIndex(dimension => dimension.type === `${dimensionForEdit.type}-countries`);
      scopeCopy.dimensions[dimensionSiblingIndex].value = countryValues;
    }
    else if(dimensionForEdit.type === DimensionType.Import) {
      const dimensionSiblingIndex = scopeCopy.dimensions.findIndex(dimension => dimension.type === `${dimensionForEdit.type}-countries`);
      scopeCopy.dimensions[dimensionSiblingIndex].value = countryValues;
    }
    else if(dimensionForEdit.type === DimensionType.Locations){
      const dimensionSiblingIndex = scopeCopy.dimensions.findIndex(dimension => dimension.type === `${dimensionForEdit.type}-countries`);
      scopeCopy.dimensions[dimensionSiblingIndex].value = countryValues;
    }
    
    updateScope(scopeCopy);
    setDimensionForEdit({} as Dimension);
  };

  const addDefaultDimensionIfNotAddedAlready = (scope: Scope): Scope => {
    // this would generally be needed for old applicability but if new default dimension is added to family.
    let missingDefaultDimensions: string[] = [];
    const presentDimensions = scope.dimensions.map(dimension => dimension.type);
    defaultDimensionsFamilyData.forEach(defaultDimension => {
      if (!presentDimensions.includes(defaultDimension.dimensionCode)) {
        missingDefaultDimensions.push(defaultDimension.dimensionCode);
      }
    })
    return {
      ...scope,
      dimensions: [
        ...scope.dimensions,
        ...buildDimensionsWithAllValues(missingDefaultDimensions, allDimensionsData, regions)
      ]
    }
  }

  const calculateOriginDestinationConfigurationFlag = useMemo(() => {
    return scopes.map(scope => scope.dimensions
      .map(dimension => dimension.type))
      .some(dimensionList => dimensionList.includes("imports") || dimensionList.includes("exports"));
  }, [scopes]);

  const calculateLocationConfigurationFlag = useMemo(() => {
    return scopes.map(scope => scope.dimensions
      .map(dimension => dimension.type))
      .some(dimensionList => dimensionList.includes("locations"));
  }, [scopes]);

  const editDimension = (dimension: Dimension, scope : Scope) => {
    setDimensionForEdit(dimension); 
    if(dimension.type === DimensionType.Export) {
      setDimensionSibling(scope.dimensions.find(dimension => dimension.type === DimensionType.ExportCountry));
    }
    else if(dimension.type === DimensionType.Import) {
      setDimensionSibling(scope.dimensions.find(dimension => dimension.type === DimensionType.ImportCountry));
    }
    else if(dimension.type === DimensionType.Locations) {
      setDimensionSibling(scope.dimensions.find(dimension => dimension.type === DimensionType.LocationsCountry));
    }
    setScopeForEdit(addDefaultDimensionIfNotAddedAlready(scope))
  }

  return (
    <div className={styles.ApplicabilitiesView} data-testid="applicabilitiesView">
      {(isLoadingDefaultDimensions && isLoadingAllDimensionsData) && <SpinnerComponent />}
      <div className={styles.scopeCountAddScopeButton}>
        <h2>{`${getScope(scopes).length}  ${scopes.length == 1 ? "Applicability Scope Defined" : "Applicabilities Scope Defined"}`}</h2>
      
        {editMode && (
          <div>
            {isAuthorisedToEdit && <Button
              label="Add Applicability Scope"
              icon="pi pi-plus"
              className="p-button-outlined p-button-secondary"
              onClick={() => addScope()}
            />}
          </div>
        )}
      </div>
      {getScope(scopes).map((scope, index) => (
        <div
          className={styles.scopeCards}
          key={index.toString()}
        >
          <ScopeCard2
            setScopeValidity={(isValid: boolean, scopeCode: string) => checkScopesValidation(isValid, scopeCode)}
            saveScope={(s: Scope) => updateScope(s)}
            scope={addDefaultDimensionIfNotAddedAlready(scope)}
            deleteScope={scopes.length > 1 ? (code: string, codeUI: string) => deleteScope && deleteScope(code, codeUI) : undefined}
            editMode={editMode}
            defaultDimensions={defaultDimensionsFamilyData}
            scopeIndex={index}
            editDimension={(dimension: Dimension) => editDimension(dimension, scope)}
            isAuthorisedToEdit={isAuthorisedToEdit}
            originDestinationConfigurationFlag={calculateOriginDestinationConfigurationFlag}
            locationConfigurationFlag={calculateLocationConfigurationFlag}
            headerInfo={headerInfo}
          />
        </div>
      ))}
      {
        dimensionForEdit?.type != undefined &&
        <EditDimensionView scope={scopeForEdit} mainTree={regions || []} dimension={dimensionForEdit} dimensionSibling={dimensionSibling}
          header={`${getDimensionDefinition(dimensionForEdit.type)?.name} for ${scopeForEdit.name}`}
          searchKey={'name'}
          onEditDone={onEditDone}
          onHide={() => setDimensionForEdit({} as Dimension)} />
      }
    </div>
  );
};

export default ApplicabilitiesView;
